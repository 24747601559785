import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropzone } from '@commonsku/styles';

import { createSpinnerPopup, closePopup } from '../actions/popup';
import { createAddCollectionImage } from '../actions/collection';
import { createUploadFile } from '../actions/file';

import { BASE_ZINDEX } from '../popup-factory';
import { getImageSrc } from '../utils';

import Img from './Img';

const DEFAULT_COLOR = '#5ca3b6';

class SelectImagePopup extends Component {

  render() {
    const { order_id, display_order, images, selected_id, onSelectImage, onUploadImage, onClosePopup, index } = this.props;
    return (
      <div className="reveal large" style={{display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index}} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3>Select an Image</h3>
            <a className="button" style={{ position: 'fixed', top: '1rem', right: '1rem' }} onClick={() => onClosePopup()}>Close</a>
            <a className="button" style={{ position: 'fixed', top: '1rem', right: '6rem' }}>
              <Dropzone style={{ border: 'none' }} onDrop={onUploadImage}>
                <><i className="fi-upload" style={{ fontSize: '0.9em' }}></i>&nbsp;&nbsp;Upload Image</>
              </Dropzone>
            </a>
          </div>
        </div>
        <div className="row full-width popup-content">
          {images.map(i =>
            <div key={i.file_id} className="small-6 medium-3 large-2 columns end" style={{ position: 'relative', padding: '0.5rem' }} onClick={() => onSelectImage(i.file_id)}>
            <div style={{ marginTop: '100%' }}></div>
              <div key={i.file_id} style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, textAlign: 'center', margin: '0.5rem', border: selected_id === i.file_id ? `4px solid ${DEFAULT_COLOR}` : null}}>
                <Img style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }} src={getImageSrc(i, 'small')} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUploadImage: image => Promise.resolve(dispatch(createSpinnerPopup('Uploading...'))).then(
    () => dispatch(createUploadFile(ownProps.order_id, 'ORDER', image[0]))
  ).then(
    action => dispatch(createAddCollectionImage(ownProps.order_id, action.payload.id, ownProps.display_order)),
    () => dispatch(closePopup())
  ).then(
    () => {
      dispatch(closePopup());
      dispatch(closePopup());
    }
  ),
  onSelectImage: file_id => dispatch(createAddCollectionImage(ownProps.order_id, file_id, ownProps.display_order)).then(() => dispatch(closePopup())),
  onClosePopup: () => dispatch(closePopup())
});

const ConnectedSelectImagePopup = connect(mapStateToProps, mapDispatchToProps)(SelectImagePopup);
export default ConnectedSelectImagePopup;
